import axios from 'axios'
import { LatLngExpression } from 'leaflet'
import { NominatimSearchResponse } from '../../redux/statelessRequests/address'

export interface GeoPoint {
  type: 'Point'
  coordinates: [number, number]
}

export interface GeoPolygonDTO {
  type: 'Polygon'
  coordinates: [number, number][][]
}

export interface GeoCircleDTO {
  radius: number
  center: GeoPoint
}

export interface InterestZoneDTO {
  polygon?: GeoPolygonDTO
  circle?: GeoCircleDTO
}

export function flipInterestZoneCoordinates(dto: InterestZoneDTO) {
  try {
    const copy = JSON.parse(JSON.stringify(dto))

    if (copy.polygon) {
      copy.polygon.coordinates = copy.polygon.coordinates.map((p: [number, number][]) =>
        p.map((points) => [points[1], points[0]]),
      )
    }

    if (copy?.circle) {
      copy.circle.center.coordinates = [
        copy.circle.center.coordinates[1],
        copy.circle.center.coordinates[0],
      ]
    }

    return copy
  } catch (e) {
    console.error(e)
  }
}

export function getPolygonCentroid(vertices: number[][]): LatLngExpression {
  let x = 0,
    y = 0,
    area = 0
  const n = vertices.length

  for (let i = 0; i < n - 1; i++) {
    const [xi, yi] = vertices[i]
    const [xj, yj] = vertices[(i + 1) % n]

    const cross = xi * yj - xj * yi
    area += cross
    x += (xi + xj) * cross
    y += (yi + yj) * cross
  }

  area /= 2
  x /= 6 * area
  y /= 6 * area

  return [x, y]
}

const $api = axios.create({})

export const findReverseAddress = async ({ lat, lon }: { lat: string; lon: string }) => {
  const url = new URL('https://nominatim.environs.life/reverse')
  const queryParams = new URLSearchParams({
    format: 'json',
    lat,
    lon,
  })
  url.search = queryParams.toString()

  const result = await $api.get(url.toString())
  return result.data as NominatimSearchResponse
}
