import { UserInterface } from '../../redux/userSlice'

export enum PROFILE_STEP {
  AVATAR = 'AVATAR',
  INTEREST_ZONE = 'INTEREST_ZONE',
  PRIVACY = 'PRIVACY',
  ABOUT_ME = 'ABOUT_ME',
  PROFESSION = 'PROFESSION',
  SKILLS = 'SKILLS',
  INTERESTS = 'INTERESTS',
  CERTIFICATES = 'CERTIFICATES',
  DATE_PLACE_OF_BIRTH = 'DATE_PLACE_OF_BIRTH',
  NATIONALITY = 'NATIONALITY',
  SEX = 'SEX',
  EDUCATION = 'EDUCATION',
  FAMILY_STATUS = 'FAMILY_STATUS',
}

export enum ROLE {
  ADMIN = 'ADMIN',
  REGIONAL_ADMIN = 'REGIONAL_ADMIN',
  LOCAL_ADMIN = 'LOCAL_ADMIN',
  COORDINATORS = 'COORDINATORS',
  TECH_SUPPORT = 'TECH_SUPPORT',
  USER = 'USER',
  USER_EMAIL_UNCONFIRMED = 'USER_EMAIL_UNCONFIRMED',
  USER_MUST_SET_PASSWORD = 'USER_MUST_SET_PASSWORD',
  USER_MUST_SET_ADDRESS = 'USER_MUST_SET_ADDRESS',
  BLOCKED = 'BLOCKED',
}

export type EditUserProfileDTO = Pick<
  UserInterface,
  | 'fullName'
  | 'aboutMe'
  | 'dateBirth'
  | 'cityBirth'
  | 'studySchool'
  | 'education'
  | 'sex'
  | 'orientation'
  | 'familyStatus'
  | 'certificates'
  | 'nationality'
>

export enum SEX {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum EDUCATION {
  HIGHSCHOOL = 'HIGHSCHOOL',
  TECHCPLLEGE = 'TECHCPLLEGE',
  PHDOTD = 'PHDOTD',
  STUDYCOLLEGE = 'STUDYCOLLEGE',
  PHD = 'PHD',
  BACHERLOR = 'BACHERLOR',
  MASTERDEGREE = 'MASTERDEGREE',
}

export enum FAMILY_STATUS {
  SINGLE = 'SINGLE',
  IN_RELATIONSHIP = 'IN_RELATIONSHIP',
  ENGAGED = 'ENGAGED',
  MARRIED = 'MARRIED',
  CIVIL_MARRIAGE = 'CIVIL_MARRIAGE',
  COMPLICATED = 'COMPLICATED',
  SEPARATE_THREADING = 'SEPARATE_THREADING',
  DIVORCED = 'DIVORCED',
  WIDOW = 'WIDOW',
}

export enum ORIENTATION {
  HETERO = 'HETERO',
  GAY = 'GAY',
  LESBIAN = 'LESBIAN',
  QUEER = 'QUEER',
  ASEXUAL = 'ASEXUAL',
  PANSEXUAL = 'PANSEXUAL',
  DEMISEXUAL = 'DEMISEXUAL',
  BISEXUAL = 'BISEXUAL',
  DIDNOTDECIDE = 'DIDNOTDECIDE',
  OTHER = 'Other',
}

export interface UpdateAvatarDTO {}
export interface UpdateAvatarResponseDTO extends Pick<UserInterface, 'stepsToComplete'> {
  filename: UserInterface['avatarFileName']
}
