import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import $api from '../utils/tools/api'

export interface ServiceCategory {
  _id: string
  name: string
  image: string
  numberView: number
  isVisible: boolean
}

export interface ServiceSubCategory extends ServiceCategory {
  categoryId: string
}

interface State {
  categories?: ServiceCategory[]
  subCategories?: ServiceSubCategory[]
}

export const initialState: State = {}

export const fetchServiceCategories = createAsyncThunk<ServiceCategory[], void>(
  'categories/fetch-categories',
  async () => {
    const response = await $api.get('/service/category/all')
    return response.data
  },
)

export const fetchServiceSubcategories = createAsyncThunk<ServiceSubCategory[], void>(
  `categories/sub-categories`,
  async () => {
    const response = await $api.get('/service/sub-category/all')
    return response.data
  },
)

export const categoriesReducer = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceCategories.fulfilled, (state, { payload }) => {
        state.categories = payload
      })
      .addCase(fetchServiceSubcategories.fulfilled, (state, { payload }) => {
        state.subCategories = payload
      })
  },
})

export const {} = categoriesReducer.actions
export default categoriesReducer.reducer
