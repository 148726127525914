import { lazy, Suspense } from 'react'
import useSessionExpiration from './utils/hooks/useSessionExpiration'
import AppFallback from './components/Layouts/AppFallback'
import { notification } from 'antd'
import ToastProvider from './components/Toast/ToastContext'

const AppRoutes = lazy(() => import('./utils/routes/AppRoutes'))
export default function App() {
  useSessionExpiration()
  const [api, toastContextHolder] = notification.useNotification({
    stack: false,
    maxCount: 4,
  })
  return (
    <Suspense fallback={<AppFallback />}>
      {toastContextHolder}
      <ToastProvider api={api}>
        <AppRoutes />
      </ToastProvider>
    </Suspense>
  )
}
