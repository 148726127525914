import React, { useEffect, useRef } from 'react'
import { AppDispatch, useAppDispatch, useAppSelector } from '../../store'
import { useTranslation } from 'react-i18next'
import { logout, refreshAuthTokens } from '../../redux/authSlice'
import { notification } from 'antd'

export default function useSessionExpiration() {
  const { accessTokenExpiresAt, refreshTokenExpiresAt } = useAppSelector(
    (state) => state.auth,
  )

  const logoutTimeoutRef = useRef<number | ReturnType<typeof setTimeout>>()
  const refreshTimeoutRef = useRef<number | ReturnType<typeof setTimeout>>()
  const dispatch = useAppDispatch()
  const { t } = useTranslation('auth')

  useEffect(() => {
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current)
    }
    if (refreshTimeoutRef.current) {
      clearTimeout(refreshTimeoutRef.current)
    }

    if (accessTokenExpiresAt) {
      const logoutAfter = accessTokenExpiresAt - Date.now()
      const refreshAfter = logoutAfter - 5000
      const canRefresh = refreshTokenExpiresAt && refreshTokenExpiresAt > 0

      if (logoutAfter < 0) {
        // In most cases it happens on reopening a browser after a long delay

        if (canRefresh) {
          // refresh immediately
          dispatch(refreshAuthTokens())
        } else {
          // logout immediately
          dispatch(logout())
        }
      } else {
        // Timeout to logout
        logoutTimeoutRef.current = setTimeout(() => {
          notification.error({
            message: t('Your session has expired. Please log in again.'),
          })
          dispatch(logout())
        }, logoutAfter)

        if (canRefresh) {
          // Timeout to refresh if possible
          refreshTimeoutRef.current = setTimeout(() => {
            dispatch(refreshAuthTokens())
          }, refreshAfter)
        }
      }
    }
  }, [accessTokenExpiresAt])
}
