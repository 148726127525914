import axios, { InternalAxiosRequestConfig } from 'axios'
import { RootState, store } from '../../store'

export const uploadedBaseURL = process.env.REACT_APP_UPLOADED_BASE_URL || 'undefined'
export const apiBaseURL = process.env.REACT_APP_API_BASE_URL || 'undefined'
export const socketBaseURL = process.env.REACT_APP_WS_BASE_URL || 'undefined'

export interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  useRefreshToken?: boolean
}

const $api = axios.create({
  withCredentials: true,
  baseURL: `${apiBaseURL}/api`,
})

$api.interceptors.request.use((config: CustomAxiosRequestConfig) => {
  const state = store.getState() as RootState

  const token = config.useRefreshToken ? state.auth.refreshToken : state.auth.accessToken

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
})

$api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const message = error.response.data?.message
    if (message) {
      return Promise.reject(new Error(message))
    }

    return Promise.reject(error)
  },
)

export default $api
