import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  AuthDTO,
  AuthResponseDTO,
  ConfirmEmailDTO,
  ForgotPasswordClaimCodeDTO,
  ForgotPasswordRegenerateCodeDTO,
  RegistrationDTO,
  SetPasswordDTO,
} from '../utils/dto/auth.dto'
import { jwtDecode } from 'jwt-decode'
import { AddressDTO, flipAddressCoordinates } from '../utils/dto/address.dto'
import $api, { CustomAxiosRequestConfig } from '../utils/tools/api'

interface State {
  authLoaderMethod: boolean
  accessToken?: string
  accessTokenExpiresAt?: number
  refreshToken?: string
  refreshTokenExpiresAt?: number
}

export const initialState: State = {
  authLoaderMethod: false,
}

export const attemptRegenerateRegistrationConfirmCode = createAsyncThunk<void, void>(
  'bug/report',
  async () => {
    await $api.post('auth/registration/regenerate-code')
    return
  },
)

export const refreshAuthTokens = createAsyncThunk<AuthResponseDTO, void>(
  'auth/refresh',
  async () => {
    const response = await $api.post('auth/refresh', undefined, {
      useRefreshToken: true,
    } as CustomAxiosRequestConfig)

    return response.data
  },
)

export const attemptLogin = createAsyncThunk<AuthResponseDTO, AuthDTO>(
  'auth/authorization',
  async (payload) => {
    const response = await $api.post(`auth/login`, payload)
    return response.data
  },
)

export const attemptGoogle = createAsyncThunk<AuthResponseDTO, string>(
  'auth/google',
  async (payload) => {
    const response = await $api.get(`auth/google-redirect${payload}`)
    return response.data
  },
)

export const attemptFacebook = createAsyncThunk<AuthResponseDTO, string>(
  'auth/facebook',
  async (payload) => {
    const response = await $api.get(`auth/facebook-redirect${payload}`)
    return response.data
  },
)

export const attemptRegistration = createAsyncThunk<AuthResponseDTO, RegistrationDTO>(
  'auth/authorization',
  async (payload) => {
    const response = await $api.post(`auth/registration`, payload)
    return response.data
  },
)

export const registrationConfirmAccount = createAsyncThunk<
  AuthResponseDTO,
  ConfirmEmailDTO
>(`auth/registration/confirm-account`, async (payload, { dispatch }) => {
  const res = await $api.post(`auth/registration/confirm-account`, payload)
  return res.data
})

export const registrationSetAddress = createAsyncThunk<AuthResponseDTO, AddressDTO>(
  'profile/set-address',
  async (payload) => {
    const response = await $api.post(
      `auth/registration/set-address`,
      flipAddressCoordinates(payload),
    )
    return response.data
  },
)

export const forgotPasswordSendCode = createAsyncThunk<
  AuthResponseDTO | undefined,
  ForgotPasswordRegenerateCodeDTO
>(`auth/forgot-password/send-code`, async (payload) => {
  const response = await $api.post(`auth/forgot-password/send-code`, payload)

  return response.data
})

export const attemptForgotPasswordClaimCode = createAsyncThunk<
  AuthResponseDTO,
  ForgotPasswordClaimCodeDTO
>(`auth/forgot-password/claim-code`, async (payload) => {
  const response = await $api.post('auth/forgot-password/claim-code', payload)

  return response.data
})

export const attemptSetPassword = createAsyncThunk<AuthResponseDTO, SetPasswordDTO>(
  `auth/set-password`,
  async (payload) => {
    const response = await $api.post(`auth/set-password`, payload)

    return response.data
  },
)

const setTokens = (state: State, payload: AuthResponseDTO) => {
  state.accessToken = payload.accessToken
  const accessTokenDecoded: any = jwtDecode(payload.accessToken)
  state.accessTokenExpiresAt = accessTokenDecoded.exp * 1000

  state.refreshToken = payload.refreshToken
  if (payload.refreshToken) {
    const refreshTokenDecoded: any = jwtDecode(payload.refreshToken)
    state.refreshTokenExpiresAt = refreshTokenDecoded.exp * 1000
  }
}

export const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      // Handled in store.ts in rootReducer
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshAuthTokens.fulfilled, (state, { payload }) => {
        setTokens(state, payload)
      })
      .addCase(attemptLogin.fulfilled, (state, { payload }) => {
        setTokens(state, payload)
      })
      .addCase(attemptGoogle.fulfilled, (state, { payload }) => {
        setTokens(state, payload)
      })
      .addCase(attemptFacebook.fulfilled, (state, { payload }) => {
        setTokens(state, payload)
      })
      .addCase(registrationConfirmAccount.fulfilled, (state, { payload }) => {
        setTokens(state, payload)
      })
      .addCase(registrationSetAddress.fulfilled, (state, { payload }) => {
        setTokens(state, payload)
      })
      .addCase(forgotPasswordSendCode.fulfilled, (state, { payload }) => {
        if (payload) {
          setTokens(state, payload)
        }
      })
      .addCase(attemptForgotPasswordClaimCode.fulfilled, (state, { payload }) => {
        setTokens(state, payload)
      })
      .addCase(attemptSetPassword.fulfilled, (state, { payload }) => {
        setTokens(state, payload)
      })
  },
})

export const { logout } = authReducer.actions
export default authReducer.reducer
