import { Flex, Layout } from 'antd'
import Logo from '../../assets/logo.png'
import './layouts.scss'

export default function AppFallback() {
  return (
    <Layout className="cloud">
      <Flex justify="center" align="center" flex={1}>
        <img src={Logo} alt="NeighborHarbor Logo" />
      </Flex>
    </Layout>
  )
}
