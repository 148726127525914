import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Interests, Professions, Skills } from '../utils/localization/i18n'
import { AddressDTO } from '../utils/dto/address.dto'
import { EDUCATION, FAMILY_STATUS, ORIENTATION, SEX } from '../utils/dto/user.dto'
import $api from '../utils/tools/api'

export interface Neighbour {
  _id: string
  email: string
  fullName: string
  avatarFileName?: string
  address: AddressDTO
  aboutMe: string
  dateBirth: string
  cityBirth: string
  sex: SEX
  orientation: ORIENTATION
  education: EDUCATION
  studySchool: string
  familyStatus: FAMILY_STATUS
  nationality: string
  certificates: string[]
  professions: (Professions | string)[]
  interests: (Interests | string)[]
  skills: (Skills | string)[]
}

export interface SearchNeighboursRequest {
  q?: string
  offset: number
  readonly sex?: SEX
  readonly orientation?: ORIENTATION
  readonly nationality?: string
  readonly age?: {
    min: number
    max: number
  }
  readonly profession?: string
  readonly education?: EDUCATION
  readonly skills?: string[]
  readonly interests?: string[]
}

type NeighbourWithDistance = Neighbour & { distance: number }

type SearchNeighboursResponse = NeighbourWithDistance[]

interface State {
  neighbours?: NeighbourWithDistance[]
  hasMore?: boolean
}

export const initialState: State = {}
//#region methods

export const attemptSearchNeighbours = createAsyncThunk<
  SearchNeighboursResponse,
  SearchNeighboursRequest
>('search/neighbours', async (payload, { getState, dispatch }) => {
  const response = await $api.post(`search/neighbours`, payload)
  return response.data
})

export const searchSlice = createSlice({
  name: 'searchSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(attemptSearchNeighbours.pending, (state, { payload }) => {
      state.hasMore = undefined
    })
    builder.addCase(attemptSearchNeighbours.fulfilled, (state, action) => {
      if (action.payload.length < 20) {
        state.hasMore = false
      } else {
        state.hasMore = true
      }
      if (action.meta.arg.offset && state.neighbours) {
        state.neighbours = [...state.neighbours, ...action.payload]
      } else {
        state.neighbours = action.payload
      }
    })
  },
})

export const {} = searchSlice.actions
export default searchSlice.reducer
