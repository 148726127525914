import { NominatimSearchResponse } from '../../redux/statelessRequests/address'
import { GeoPoint } from './geo.dto'

export interface AddressDTO {
  readonly displayName: string
  readonly coordinates: GeoPoint
  readonly house?: string
  readonly street?: string
  readonly city?: string
  readonly postcode?: string
  readonly country?: string
}

export function flipAddressCoordinates(dto: AddressDTO) {
  try {
    const copy = JSON.parse(JSON.stringify(dto))
    if (copy.coordinates && copy.coordinates.coordinates) {
      copy.coordinates.coordinates = [
        copy.coordinates.coordinates[1],
        copy.coordinates.coordinates[0],
      ]
    }
    return copy
  } catch (e) {
    console.error(e)
  }
}

export function convertNominatimResponseToAddressDTO(
  response: NominatimSearchResponse,
): AddressDTO {
  return {
    displayName: response.display_name,
    coordinates: {
      type: 'Point',
      coordinates: [Number(response.lat), Number(response.lon)],
    },
    house: response.address.house_number,
    city: response.address.city || response.address.town,
    postcode: response.address.postcode,
    country: response.address.country_code,
  }
}
